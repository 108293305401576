import { CurrentWhatsapp } from '@/classes/CurrentWhatsapp'
import { SmsParser } from '@rtorralba/smsparser'
import EventBus from '@/util/EventBus.js'
import getEnv from '@/util/env'
import { Landing } from '@/components/Template/LandingEditor/classes/Landing'
import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import CampaignService from '@/services/campaign.service'
import CampaignDltValidationService from '@/services/campaign-dlt-validation.service'
import Senders from '@/components/FormConfiguration/Senders.vue'
import UrlsDialog from '@/views/Pages/Tools/Urls/UrlsDialog/UrlsDialog.vue'
import UsersUrlsService from '@/services/users-urls.service'
import CountryService from '@/services/country.service'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import VariableSubstitutionDialog from '@/components/VariableSubstitutionDialog/VariableSubstitutionDialog.vue'
import HeadboardOption from '@/models/whatsapp-editor/HeadBoardOption'
import multimediaService from '@/services/multimedia.service'
import HeadboardType from '../../../../../models/whatsapp-editor/HeadboardType'

export default {
  name: 'SecondSection',
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    preview: {
      type: Object,
      required: true,
    },
    componentsData: {
      type: Object,
      required: true,
    },
    fields: {
      type: Object,
      required: true,
    },
    regex: {
      type: Object,
      required: true,
    },
    currentWhatsappIn: {
      type: Object,
      required: true,
    },
    isValidDlt: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    canUseCustomSender: {
      type: Boolean,
      default: false,
    },
    disabledEditTemplateWhatsapp: {
      type: Boolean,
      required: false,
      default: false,
    },
    template: {
      type: Object,
      required: true,
    },
  },
  components: {
    EmojiPickerInput,
    Senders,
    UrlsDialog,
    SelectCountry,
    VariableSubstitutionDialog,
  },
  data: function () {
    return {
      currentWhatsapp: this.currentWhatsappIn,
      isLandingMessage: false,
      dropdownStyle: 'border-bottom: 1px solid #b7c5de; width:800px',
      sender: '',
      landing: { id: null, name: '', preview: '' },
      landingTemplate: new Landing(null),
      extraEncodings: [],
      showTooltip: false,
      MAX_MESSAGE_CHUNKS: 5,
      showAlertCharacters: false,
      isShortWhatsapp: false,
      chunkCountChange: false,
      pollsMenu: false,
      urlsDialog: false,
      loadingSendingCost: false,
      timeout: null,
      showModalSendingWhatsappValidate: false,
      showModalDltVarSubstitution: false,
      selectedDltMessage: null,
      whatsappMessageValidates: [],
      messageValidateId: false,
      showEmoji: true,
      formId: 0,
      showFormEdit: false,
      countries: [],
      showModalVarSubstitution: {
        show: false,
      },
      showModalVarSubstitutionHeadboard: {
        show: false,
      },
      showModalVarSubstitutionUrl: [],
      showModalVarSubstitutionCode: {
        show: false,
      },
      enableSusbtitutionDialog: false,
      automaticResponsesOptions: [
        { value: 1, text: 'Respuesta normal', disabled: false },
        { value: 2, text: 'Desactivar marketing', disabled: true },
      ],
      dialogCodeCustomField: false,
      isSelectingFile: false,
      HeadboardType: HeadboardType,
    }
  },
  methods: {
    validateAutomaticResponse () {
      if (this.campaign.sendings[0].channel.responseActionCount >= 3) {
        return true
      }

      return false
    },
    changeHeadboardOption () {
      this.campaign.sendings[0].channel.headboard = ''
    },
    deleteHeadboard () {
      this.campaign.sendings[0].channel.headboard = ''
    },
    addAutomaticResponse () {
      this.campaign.sendings[0].channel.responseActionCount++
    },
    selectWhatsappType (type) {
      if (this.disabledEditTemplateWhatsapp) {
        return
      }
      this.campaign.sendings[0].channel.selectedHeadboardType = type
    },
    openModalSendingWhatsappValidate () {
      this.showModalSendingWhatsappValidate = true
      this.getCampaignSendingWhatsappValidate()
    },
    closeModalSendingWhatsappValidate () {
      this.showModalSendingWhatsappValidate = false
    },
    acceptedModalSendingWhatsappValidate () {
      this.selectedDltMessage = this.whatsappMessageValidates.find(message => message.id === this.messageValidateId)
      if (this.dltVarFieldsCount > 0) {
        this.showModalDltVarSubstitution = true
      } else {
        this.selectValidatedMessage(this.selectedDltMessage)
      }
      this.closeModalSendingWhatsappValidate()
    },
    acceptDltVariableSubstitution (message) {
      this.showModalDltVarSubstitution = false
      this.selectValidatedMessage(message)
    },
    selectValidatedMessage (message) {
      this.$emit('onMessageValidateSelected', message)
      this.parseWhatsapp()
    },
    getCampaignSendingWhatsappValidate () {
      CampaignDltValidationService.getApproved()
      .then((response) => {
        this.whatsappMessageValidates = response
      })
    },
    clearVariables (text) {
      text = text.replace(this.regex.variablesRegex, '')
      text = text.replace(this.regex.urlRegExp, this.regex.EXAMPLE_SHORTENED_URL)
      return text
    },
    validateIsClickable (message) {
      const expresion = message.match(/{LAND_URL}|{URL_[0-9]+}|{FORM_[0-9]+}|{ATTACHMENT_[0-9]+}|{UNSUB_URL}|{[\w-]+\s*\|\s*shorten}/)
      if (expresion === null) {
        this.campaign.sendings[0].channel.clickable = false
      } else {
        this.campaign.sendings[0].channel.clickable = true
      }
    },
    validateIsForm (message) {
      const expresion = message.match(/{FORM_[0-9]+}/)
      if (expresion === null) {
        this.showFormEdit = false
      } else {
        this.showFormEdit = true
      }
    },
    parseWhatsapp () {
      const text = this.campaign.sendings[0].channel.message
      this.updateCurrentWhatsapp(text)
    },
    validateShowEmoji (currentWhatsapp) {
      const chunks = currentWhatsapp.chunks
      const resto = (currentWhatsapp.maxCharacters - (currentWhatsapp.characters))

      if (chunks >= 2 && (resto === 1 || resto === 0)) {
        this.showEmoji = false
      } else {
        this.showEmoji = true
      }
    },

    updateCurrentWhatsapp (text) {
      this.currentWhatsapp = CurrentWhatsapp.whatsappParserResultToCurrentWhatsapp(
        this.currentWhatsapp.encoding,
        text,
        this.currentWhatsapp.maxCharacters,
        this.currentWhatsapp.chunks,
      )
      this.validateShowEmoji(this.currentWhatsapp)
    },

    showAlerts () {
      this.alerts.whatsappLengthCustomFields.show = this.hasCustomFields(this.campaign.sendings[0].channel.message, this.regex.variablesRegex)
      this.alerts.invalidCustomFields.show = this.hasInvalidFields(this.campaign.sendings[0].channel.message)
    },

    whatsappLengthCustomFieldsMessage () {
      return this.$t('Ten en cuenta que si los campos personalizados utilizados en el contenido del mensaje superan') + '<b style="font-size: 16px;">' + ' ' + this.currentWhatsapp.maxCharactersInfo + '</b>' + ' ' + this.$t('caracteres, se generarán mensajes concatenados') + '.'
    },

    hasInvalidFields (text) {
      this.fields.currentFieldsMessage = this.getCurrentFieldsArray(this.regex.variablesRegex, text)
      this.fields.currentUrlFieldsMessage = this.getCurrentFieldsArray(this.regex.urlRegExp, text)
      if (!this.fields.availableFields) {
        return false
      }
      const array = Object.keys(this.fields.availableFields)
      for (let i = 0; i < this.fields.currentFieldsMessage.length; i++) {
        if (array.indexOf(this.fields.currentFieldsMessage[i]) < 0) {
          return true
        }
      }
      return false
    },

    getCurrentFieldsArray (regExp, s) {
      let match = regExp.exec(s)
      const matches = []

      while (match != null) {
        match = match[0]
        matches.push(match.substr(1, (match.length - 2)))
        match = regExp.exec(s)
      }
      return matches
    },

    getMatches (regExp, s) {
      let match = regExp.exec(s)
      const matches = []

      while (match != null) {
        match = match[0]
        matches.push(match)
        match = regExp.exec(s)
      }
      return matches
    },

    hasCustomFields (text, regExp) {
      let matches = []

      if (text) {
        matches = this.getMatches(regExp, text)
      }
      this.currentWhatsappIn.hasCustomFields = false
      if (matches.length > 0) {
        this.currentWhatsapp.maxCharactersInfo = this.currentWhatsapp.maxCharacters - this.currentWhatsapp.characters
        this.currentWhatsappIn.hasCustomFields = true
        this.alerts.whatsappLengthCustomFields.textContent = this.whatsappLengthCustomFieldsMessage()
        return true
      }
      return false
    },

    addEmojiToMessage (emoji) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.campaign.sendings[0].channel.message = before + emoji + after

      this.$nextTick().then(() => {
        textarea.selectionStart = textarea.value.length
        textarea.click()
        this.parseWhatsapp()
      })
    },

    addFieldToMessage (item, type) {
      if (item !== null) {
        let fieldName = item
        if (type === 'field') {
          fieldName = '{' + item + '}'
        }
        if (type === 'form') {
          fieldName = '{FORM_' + item + '}'
        }
        if (type === 'attachment') {
          fieldName = '{ATTACHMENT_' + item + '}'
        }
        if (type === 'url') {
          fieldName = '{URL_' + item + '}'
        }
        if (type === 'last-whatsapp') {
          fieldName = this.componentsData.lastWhatsapp[item].message
        }
        if (type === 'bookmark') {
          fieldName = this.componentsData.bookmarks[item].message
        }
        this.insertFieldAtCursor(fieldName)
        this.parseWhatsapp()
        this.fields.currentFieldsMessage = this.getCurrentFieldsArray(this.regex.variablesRegex, this.campaign.sendings[0].channel.message)
        this.fields.currentUrlFieldsMessage = this.getCurrentFieldsArray(this.regex.urlRegExp, this.campaign.sendings[0].channel.message)
        this.showAlerts()
      }
    },

    toogleBookMarks () {
      this.campaign.sendings[0].channel.bookmark = !this.campaign.sendings[0].channel.bookmark
    },

    insertFieldAtCursor (myValue) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      if (myValue === '{UNSUB_URL}' && sentence.includes(myValue)) return
      if (!myValue.length) return

      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.campaign.sendings[0].channel.message = before + myValue + after

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + myValue.length
        textarea.selectionEnd = pos + myValue.length
        textarea.click()
      })
    },

    removeSpecialChars () {
      this.campaign.sendings[0].channel.message = SmsParser.substitute(this.campaign.sendings[0].channel.message, this.campaign.sendings[0].channel.encoding, this.currentWhatsapp.encoding).message
      this.campaign.sendings[0].channel.encoding = this.currentWhatsapp.encoding
      this.alerts.whatsappLength.show = false
      this.removeEmojis()
      return this.parseWhatsapp()
    },

    removeEmojis () {
      this.campaign.sendings[0].channel.message = this.campaign.sendings[0].channel.message.replace('??', '')
    },

    acceptSpecialChars () {
      this.alerts.whatsappLength.show = false
      return this.parseWhatsapp()
    },

    addBookmark () {
      if (!this.campaign.sendings[0].channel.message) {
        return
      }
      this.campaign.sendings[0].channel.bookmark = this.campaign.sendings[0].channel.bookmark === 1 ? 0 : 1
    },

    showModalPrices () {
      EventBus.$emit('showModalPrices', this.currentWhatsapp)
      // this.$emit('onRefreshPreview')
    },

    showMultimedia (currentRootDirectory) {
      this.isSelectingFile = true
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },

    closeUrlsDialog () {
      this.urlsDialog = false
    },
    onLoading (value) {
      EventBus.$emit('showLoading', value)
    },
    reloadUrls () {
      UsersUrlsService.getFormAvailableUrls()
      .then(
        (response) => {
          this.componentsData.availableUrls = response
        },
        () => {},
      )
      .finally(() => {

      })
    },
    setLoading: function (isLoading = false) {
      this.loadingSendingCost = isLoading
    },
    editForm () {
      this.$emit('showForm', this.formId)
    },
    getAllCountries () {
      CountryService
        .getAllCountries()
        .then(
          (response) => {
            this.countries = response.map((country) => {
              return {
                value: country.iso_code_2,
                text: `${country.name} +(${country.prefix})`,
              }
            })
          },
          () => {},
        )
    },
    async selectFile (url, fileInfo) {
      if (!this.isSelectingFile) {
        return
      }
      this.isSelectingFile = false

      const [simpleType, tooBig, sizeTooBig] = HeadboardOption.fileType(fileInfo)

      if (simpleType === 'audio') {
        // Translate wav to mp3
        await multimediaService.getMp3FromAudio({
          filename: fileInfo.name + '.' + fileInfo.extension,
        })
          .then(response => {
            url = response.url
            fileInfo = response.fileInfo
          })
      }

      if (tooBig) {
        EventBus.$emit('showAlert', 'danger', this.$t('Has elegido un fichero demasiado grande. El tamaño máximo para este tipo de fichero es ') + sizeTooBig + 'MB.')
      } else {
        if (!simpleType?.length) {
          EventBus.$emit('showAlert', 'danger', this.$t('Este tipo de fichero no puede ser enviado a través de WhatsApp'))
        } else {
          this.campaign.sendings[0].channel.headboard = {
            url: `${getEnv('VUE_APP_ASSETS_URL')}${url}`,
            mimeType: fileInfo.mimeType,
          }
        }
      }
      console.log('SecondSection.selectFile', this.campaign.sendings[0].channel.headboard)
    },

    acceptVariableSubstitutionActionUrl (url) {
      this.campaign.sendings[0].channel.callActionUrl = url
    },

    acceptVariableSubstitutionHeadBoard (message) {
      this.campaign.sendings[0].channel.headboard = message
    },
    acceptVariableSubstitution (message) {
      this.campaign.sendings[0].channel.message = message
    },
    acceptVariableSubstitutionCode (message) {
      this.campaign.sendings[0].channel.message = message
    },
    acceptVariableSubstitutionUrl (callToAction, url) {
      // console.log('acceptVariableSubstitutionUrl', callToAction, url)
      callToAction.url = url
    },
    hasValidateCustomFieldsActionUrl (callToAction) {
      return callToAction.url.match(/\{COL.\}/g)
    },
    countVarsUrl (url) {
      return url.split('{COL+}').length - 1
    },
  },
  watch: {
    canUseCustomSender (newValue, oldValue) {
      if (!newValue) {
        this.sender = 'Test'
      }
    },
    senderStatus () {
      this.sender = this.campaign.sendings[0].channel.sender
    },
    componentStatus () {
      this.parseWhatsapp()
    },
    'campaign.countryIso': function (val) {
      const data = { countryIso: this.campaign.countryIso }
      CampaignService.getOptOut(data)
        .then(
          (response) => {
            this.campaign.optOut = response.length ? ' ' + response : response
            this.parseWhatsapp()
          },
        )
    },
    'currentWhatsapp.chunks': function (value) {
      this.chunkCountChange = true
      setTimeout(() => {
        this.chunkCountChange = false
      }, 500)
      this.currentWhatsappIn.chunks = value
    },
    messageWatcher: function () {
      if (this.timeout !== null) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.$emit('onRefreshPreview')
      }, 2000)
    },
    latitude: function (newValue, oldValue) {
      this.$nextTick(() => {
        if (this.campaign.sendings[0].channel.localizationData.lat) {
          if (parseFloat(newValue) > 90) {
            this.campaign.sendings[0].channel.localizationData.lat = 90
          } else if (parseFloat(newValue) < -90) {
            this.campaign.sendings[0].channel.localizationData.lat = -90
          }
        }
      })
    },
    longitude: function (newValue, oldValue) {
      this.$nextTick(() => {
        if (this.campaign.sendings[0].channel.localizationData.long) {
          if (parseFloat(newValue) > 180) {
            this.campaign.sendings[0].channel.localizationData.long = 180
          } else if (parseFloat(newValue) < -180) {
            this.campaign.sendings[0].channel.localizationData.long = -180
          }
        }
      })
    },
  },
  computed: {
    validateWhatsappHeaderType () {
      return [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.campaign.sendings[0].channel.selectedHeadboardType)
    },
    validateWhatsappOptionType () {
      return [
        HeadboardType.headboardTypes.OPTION_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.campaign.sendings[0].channel.selectedHeadboardType) &&
      (this.campaign.sendings[0].channel.automaticResponses.length + this.campaign.sendings[0].channel.callToActionResponses.length) > 0
    },
    validateWhatsappCallActionType () {
      return [
        HeadboardType.headboardTypes.CALL_ACTION_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
      ].includes(this.campaign.sendings[0].channel.selectedHeadboardType)
    },
    validateWhatsappResponseType () {
      return [
        HeadboardType.headboardTypes.RESPONSE_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
      ].includes(this.campaign.sendings[0].channel.selectedHeadboardType)
    },
    latitude: function () {
      const localizationData = this.campaign.sendings[0].channel.localizationData
      return localizationData ? parseFloat(localizationData.lat) : null
    },
    longitude: function () {
      const localizationData = this.campaign.sendings[0].channel.localizationData
      return localizationData ? parseFloat(localizationData.long) : null
    },
    countVarsMessage () {
      return this.campaign.sendings[0].channel.message.split('{COL+}').length - 1
    },
    isValidHeader () {
      if ([2, 4, 6].includes(this.campaign.sendings[0].channel.selectedHeadboardType)) {
        return this.campaign.sendings[0].channel.headboard !== '' && this.campaign.sendings[0].channel.headboard.split('{COL+}').length - 1 === 0
      }
      return true
    },
    isValidCallToAction () {
      if ([3, 4].includes(this.campaign.sendings[0].channel.selectedHeadboardType)) {
        return this.campaign.sendings[0].channel.callActionUrl.split('{COL+}').length - 1 === 0
      }
      return true
    },
    hasContacts () {
      return this.preview.totalRecipients > 0
    },
    hasValidateCustomFieldsMessage () {
      return this.campaign.sendings[0].channel.message.match(/\{COL.\}/g)
    },
    hasValidateCustomFieldsHeadBoard () {
      return JSON.stringify(this.campaign.sendings[0].channel.headboard).match(/\{COL.\}/g)
    },
    hasValidateCustomFieldsCodeField () {
      return this.campaign.sendings[0].channel?.customVerificationCodeField?.match(/\{COL.\}/g)
    },
    maxTextAreaLength () {
      return ((this.currentWhatsapp.maxCharacters / this.currentWhatsapp.chunks) * this.MAX_MESSAGE_CHUNKS) - this.campaign.optOut?.length
    },
    dltVarFieldsCount () {
      return (this.selectedDltMessage.template_content.match(/\{#.+?#\}/g) || []).length
    },
    fieldsAsArray () {
      const previewFields = this.preview.contactsFieldsValue[this.preview.contactPreview]
      const fields = []
      for (const property in previewFields) {
        const preview = previewFields[property] ?? this.$t('Sin datos')
        fields.push({
          value: property,
          text: `${property} - ${preview}`,
        })
      }
      return { fields: fields }
    },
    alerts () {
      let whatsappLengthContent = ''
      if (this.campaign.sendings[0].channel.encoding === 'GSM-PT') {
        whatsappLengthContent = this.$t('Si introduces <a class="link-primary" href="https://apidocs.360nrs.com/es/#conjunto-de-caracteres-gsm-pt" target="_blank">caracteres especiales</a> (tildes, diéresis) tu mensaje no puede tener más de 155 caracteres.<br><br>Si quieres usar caracteres especiales pulsa <b>Continuar</b>.<br>Si prefieres mantener los 160 caracteres pulsa <b>Restaurar</b>.')
      }
      if (this.campaign.sendings[0].channel.encoding === 'UTF-16') {
        whatsappLengthContent = this.$t('Si introduces <a class="link-primary" href="https://apidocs.360nrs.com/es/#conjunto-de-caracteres-gsm" target="_blank">caracteres especiales</a> (tildes, diéresis) tu mensaje no puede tener más de 70 caracteres.<br><br>Si quieres usar caracteres especiales pulsa <b>Continuar</b>.<br>Si prefieres mantener los 160 caracteres pulsa <b>Restaurar</b>.')
      }
      return {
        whatsappLength: {
          show: false,
          textContent: whatsappLengthContent,
          buttonText: this.$t('Eliminar caracteres especiales').toString(),
          type: 'warning',
        },
        whatsappLengthCustomFields: {
          show: false,
          textContent: '',
          type: 'info',
        },
        invalidCustomFields: {
          show: false,
          textContent: this.$t('El mensaje contiene campos personalizados inválidos').toString(),
          type: 'warning',
        },
      }
    },
    messageWatcher () {
      return this.campaign.sendings[0].channel.message
    },
    senderStatus () {
      return this.campaign.sendings[0].channel.sender !== ''
    },
    componentStatus () {
      return this.componentsData.maxMessageChunks !== null
    },
    senders: function () {
      const senderList = []
      const that = this
      if (this.componentsData.previousSenders) {
        this.componentsData.previousSenders.map(function (item, index) {
          senderList.push({
            type: that.$t('Últimos remitentes').toString(),
            text: item,
            value: 'last' + index,
          })
        })
      }
      if (this.componentsData.twoWaySenders) {
        this.componentsData.twoWaySenders.map(function (item, index) {
          senderList.push({
            type: 'TwoWay',
            text: item,
            value: 'TwoWay' + index,
          })
        })
      }
      if (this.componentsData.twoWayDedicatedSenders) {
        this.componentsData.twoWayDedicatedSenders.map(function (item, index) {
          senderList.push({
            type: 'TwoWay ' + that.$t('Dedicado').toString(),
            text: item,
            value: 'dedicado' + index,
          })
        })
      }
      if (this.componentsData.verifiedSenders) {
        this.componentsData.verifiedSenders.map(function (item, index) {
          senderList.push({
            type: that.$t('Verificado').toString(),
            text: item,
            value: 'verified' + index,
          })
        })
      }
      return senderList
    },

    validatePhone () {
      if (this.campaign.sendings[0].channel.callActionPhone.match('/^[0-9]+$/')) {
        alert('Es numérico')
      } else {
        alert('No es numérico')
      }
    },
  },
  mounted () {
    this.sender = this.campaign.sendings[0].channel.sender
    EventBus.$on('multimediaSelected', (url, type, fileId, nameTemplate, fileInfo) => {
      this.selectFile(url, fileInfo)
    })

    this.getAllCountries()

    this.campaign.sendings[0].channel.callToActionResponses.forEach(_ => {
      this.showModalVarSubstitutionUrl.push({
        show: false,
      })
    })

    setTimeout(() => {
      this.enableSusbtitutionDialog = true
    }, 3000)
  },
  unmounted () {
    EventBus.$off('multimediaSelected')
    EventBus.$off('loadingSendingCost', this.setLoading)
  },
}
